import React from "react"
import { Link } from "gatsby"
import TwitterIcon from "../svg/twitter-icon.inline.svg"
import FacebookIcon from "../svg/facebook-icon.inline.svg"
import InstagramIcon from "../svg/instagram-icon.inline.svg"
import FooterCalloutImage from "../svg/footer-illustration.inline.svg"
import PressModule from "./PressModule"
import CNNLogo from "../assets/svgs/logos/cnn.svg"
import FastcoLogo from "../assets/svgs/logos/fastco.svg"
import QuartzLogo from "../assets/svgs/logos/quartz.svg"
import TechCrunchLogo from "../assets/svgs/logos/techcrunch.svg"
import WellGoodLogo from "../assets/svgs/logos/wellgood.svg"
import RightArrow from "../svg/right-arrow-white.inline.svg"
import branch from "../assets/images/branch.png"

import "./footer.scss"
function Footer({
  removeFooterCallout,
  pressInFooter,
  footerBackgroundColor,
  footerEyebrow,
  footerHeader,
  footerSubhead,
  footerCtas,
  pressSubhead,
  footerLinks,
}) {
  return (
    <div className="new-layout">
      <div className={`footer_container `}>
        {!removeFooterCallout && (
          <div className={`${footerBackgroundColor}`}>
            <div className={`callout f jcs rel  container--xxxl`}>
              <div className="rel footer-image">
                <FooterCalloutImage />
              </div>

              <div className=" abs copy-container f y aic ">
                <div className="callout-copy">
                  <h6 className="eyebrow fw5 caps ls1 ctl">
                    {footerEyebrow || "For Providers"}
                  </h6>
                  <h1 className="cw mb05 h2-style">
                    {footerHeader || "Grow your practice with Alma."}
                  </h1>
                  <p className="cw mb1">
                    {footerSubhead ||
                      "Get the tools and resources you need to build a thriving private practice, powered by Alma."}
                  </p>
                  {footerLinks && footerLinks.length > 1 ? (
                    <div className="f x double-cta">
                      {footerLinks.map((footerLink, idx) => {
                        return footerLink && footerLink.includes("http") ? (
                          <a
                            className={`cw fw5 mt1 f arrow-hover btn light ac ${
                              idx === 0 ? "fill" : ""
                            } mr1`}
                            key={idx}
                            href={footerLink || "/for-providers"}
                          >
                            {footerCtas[idx] || "Learn more about membership"}
                          </a>
                        ) : (
                          <Link
                            className={`cw fw5 mt1 f arrow-hover btn light ac ${
                              idx === 0 ? "fill" : ""
                            } mr1`}
                            to={footerLink || "/for-providers"}
                            key={idx}
                          >
                            {footerCtas[idx] || "Learn more about membership"}
                          </Link>
                        )
                      })}
                    </div>
                  ) : footerLinks ? (
                    footerLinks.map((footerLink, idx) => {
                      return footerLink && footerLink.includes("http") ? (
                        <a
                          className="cw fw5 mt1 f arrow-hover"
                          href={footerLink || "/for-providers"}
                          key={idx}
                        >
                          {footerCtas[idx] || "Learn more about membership"}
                          <span className="f aic pl05">
                            <RightArrow className="arrow" />
                          </span>
                        </a>
                      ) : (
                        <Link
                          className="cw fw5 mt1 f arrow-hover"
                          to={footerLink || "/for-providers"}
                          key={idx}
                        >
                          {footerCtas[idx] || "Learn more about membership"}

                          <span className="f aic pl05">
                            <RightArrow className="arrow" />
                          </span>
                        </Link>
                      )
                    })
                  ) : (
                    <Link
                      className="cw fw5 mt1 f arrow-hover"
                      to={"/for-providers"}
                    >
                      {"Learn more about membership"}

                      <span className="f aic pl05">
                        <RightArrow className="arrow" />
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {pressInFooter && (
          <PressModule
            backgroundColor={"bcgrn"}
            textColor={"cw"}
            pressContext={pressInFooter ? "footer" : null}
            header={pressSubhead || "We've been featured in"}
            logos={[
              [
                CNNLogo,
                "https://www.cnn.com/2019/06/21/tech/alma-mental-health/index.html",
              ],
              [
                FastcoLogo,
                "https://www.fastcompany.com/90457848/health-most-innovative-companies-2020",
              ],
              [
                TechCrunchLogo,
                "https://techcrunch.com/2019/06/22/while-people-puzzle-over-wework-niche-co-working-spaces-continue-gaining-traction/",
              ],
              [
                WellGoodLogo,
                "https://www.wellandgood.com/productivity-free-time-myth-covid/",
              ],
              [
                QuartzLogo,
                "https://qz.com/quartzy/1418388/alma-is-a-stylish-co-working-space-for-mental-health-professionals/",
              ],
            ]}
          />
        )}
        <div className={`footer_menu cw f jcb bcblgr`}>
          <div className="footer-column">
            <div className="footer__support block f fdc">
              <p className="column-header fw5 mb05">Questions?</p>
              <Link to="/faq">FAQs</Link>
              <a href="mailto:info@helloalma.com">info@helloalma.com</a>
              <a href="mailto:press@helloalma.com">press@helloalma.com</a>
            </div>
            {/* <div className="footer__support block f fdc">
              <p className="column-header fw5 mb05">Work With Us</p>
              <a href="https://boards.greenhouse.io/alma">Careers</a>
            </div> */}

            <div className="footer__social block ">
              <p className="column-header fw5 mb05">Follow Us</p>
              <div className="social-icons">
                <a href="https://twitter.com/withalma_" title="twitter">
                  <TwitterIcon className="social-icon" />
                </a>
                <a
                  href="https://www.facebook.com/growwithalma/"
                  title="facebook"
                >
                  <FacebookIcon className="social-icon" />
                </a>
                <a href="https://www.instagram.com/withalma/" title="instagram">
                  <InstagramIcon className="social-icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-column mobile">
            <div className="footer__social block">
              <p className="column-header fw5 mb05">Follow Us</p>
              <div className="social-icons">
                <a href="https://twitter.com/withalma_" title="twitter">
                  <TwitterIcon className="social-icon" />
                </a>
                <a
                  href="https://www.facebook.com/growwithalma/"
                  title="facebook"
                >
                  <FacebookIcon className="social-icon" />
                </a>
                <a href="https://www.instagram.com/withalma/" title="instagram">
                  <InstagramIcon className="social-icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-column">
            <p className="column-header fw5 mb05">Get Started</p>
            <ul>
              <li className="my025">
                <a href="https://helloalma.com/providers-landing">
                  Find a Provider
                </a>
              </li>
              <li className="my025">
                <Link className="desktop" to="/get-matched">
                  Free Matching Services
                </Link>
                <Link className="mobile" to="/get-matched">
                  Get Matched
                </Link>
              </li>
              <li className="my025">
                <Link to="/how-it-works">How It Works</Link>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <p className="column-header fw5 mb05">For Providers</p>
            <ul>
              <li className="my025">
                <Link to="/for-providers">Benefits</Link>
              </li>

              <li className="my025">
                <Link to="/for-providers#form">Join Alma</Link>
              </li>
              {/* <li className="my025">
                <Link to="/for-providers/#form">
                  Insurance At Alma
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="footer-column">
            <p className="column-header fw5 mb05">Information</p>
            <ul>
              <li className="my025">
                <Link to="/our-mission">Our Mission</Link>
              </li>
              <li className="my025">
                <Link to="/locations">Where We Are</Link>
              </li>
              {/* <li className="my025">
                <Link to="/faq">FAQs</Link>
              </li> */}
              <li className="my025">
                <a href="https://boards.greenhouse.io/alma">Careers</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="branch-container x f bcblgr jcc z1 rel">
          <img src={branch} alt="branch" className="branch-icon x y" />
        </div>

        <div className="footer_locations bcblgr cw f jcc ">
          <div className="inner x ">
            <p className="ma ac f fw jcc">
              <span className="nowrap">
                Alma is available in NY, NJ, CT, MA and FL.&nbsp;
              </span>
            </p>
          </div>
        </div>

        <div className="footer_bottom bcblgr f">
          <div className="inner f jcb ma cw y x f fw">
            <div className="disclaimer container--5">
              <p className="s14">
                If you or someone you know is experiencing an emergency or
                crisis and needs immediate help, call 911 or go to the nearest
                emergency room. Additional crisis resources can be found{" "}
                <a
                  className="underline s14"
                  href="https://www.apa.org/topics/crisis-hotlines"
                >
                  here.
                </a>
              </p>
            </div>

            <div className="privacy-policy container--5">
              <p className="s14">
                <a className="s14" href="https://secure.helloalma.com/pp/">
                  Privacy Policy&nbsp;
                </a>
                • Copyright Alma, 2021
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
