import React, { useState, useEffect } from "react"
import "./emailFloater.scss"
import CloseIcon from "../assets/svgs/close.svg"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const EmailFloater = () => {
  const [emailCaptureStatus, setEmailCaptureStatus] = useState(false)
  const [emailCaptureClosed, setEmailCaptureClosed] = useState(
    typeof window !== "undefined" &&
      sessionStorage.getItem("email-popup-closed") === "true"
      ? true
      : false
  )

  const scrollListener = () => {
    window.scrollY >= window.innerHeight - 100 &&
      setTimeout(() => {
        setEmailCaptureStatus(true)
      }, 0)
  }
  useEffect(() => {
    window.addEventListener("scroll", scrollListener)
    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [])

  return (
    <div
      id="email-floater"
      className={`${
        emailCaptureStatus && !emailCaptureClosed ? "open" : ""
      } z6 p15 f aic x `}
    >
      <div className="rel inner f x top">
        <div
          className="close-icon abs right pointer z1 f"
          onClick={() => {
            typeof window !== "undefined" &&
              sessionStorage.setItem("email-popup-closed", "true")
            setEmailCaptureStatus(false)
            setEmailCaptureClosed(true)
          }}
          onKeyDown={e => {
            typeof window !== "undefined" &&
              sessionStorage.setItem("email-popup-closed", "true")
            setEmailCaptureStatus(false)
            setEmailCaptureClosed(true)
          }}
          tabIndex={0}
          role="button"
        >
          <img src={CloseIcon} className="x" alt="Close Icon" />
        </div>
        <div className="text-container  x y fill f fdc jcc">
          <p className="pb05 fw5">Subscribe to The Digest</p>
          <p className="s14">
            Get the latest mental health news in your inbox every other
            Thursday.
          </p>

          <p className=" m f  bottom">
            <a
              className=" arrow-hover f btn light fill mt1"
              target="_blank"
              rel="noreferrer"
              href="https://info.helloalma.com/The-Digest-Subscribe.html?utm_source=popup"
              onClick={() => {
                trackCustomEvent({
                  category: "For Providers",
                  label: "CTA_getstarted_formsubmit",
                  action: "click",
                })
              }}
            >
              <span className="s14 ">Sign Up</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default EmailFloater
