const utmForwarding = function (opts) {
  var current = {
      state: document.location.href,
      query: document.location.search.substring(1),
      time: new Date().getTime(),
    },
    storage = {
      area: window.localStorage,
      key: "last_utm_query",
    },
    restored,
    updated = {}

  if (/(^|&)utm_/.test(current.query)) {
    storage.area[storage.key] = JSON.stringify({
      time: current.time,
      query: current.query,
    })
  } else if ((restored = JSON.parse(storage.area[storage.key] || '""'))) {
    restored.query = restored.query.includes("&restored=")
      ? restored.query.split("&restored=")[0]
      : restored.query
    if (
      window.MktoForms2 &&
      current.time - restored.time <= opts.expireDays * 864e5
    ) {
      updated.state = document.createElement("a")
      updated.state.href = current.state
      updated.state.search +=
        (updated.state.search ? "&" : "") +
        restored.query +
        "&restored=" +
        restored.time

      typeof window !== "undefined" &&
        window.history.replaceState("", {}, updated.state)
    }
  }
}

module.exports = { utmForwarding }
