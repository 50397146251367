import React, { useContext } from "react"
import "./Navbar.scss"
import NavContent from "./navContent"
import { Context, ContextDispatch } from "../context/Context"

function Navbar({
  knockoutNav,
  knockoutNavDark,
  scrollNav,
  navClosedDefault,
  mobileMenuOpen,
}) {
  const context = useContext(Context)
  const setContext = useContext(ContextDispatch)
  const mobileMenuOpenHandler = menuStatus => {
    setContext({ ...context, mobileMenuOpen: menuStatus })
  }

  return (
    <div className="nav-outer">
      <NavContent
        knockoutNav={knockoutNav}
        knockoutNavDark={knockoutNavDark}
        scrollNav={scrollNav}
        navClosedDefault={navClosedDefault}
        mobileMenuOpen={context && context.mobileMenuOpen}
        setMobileMenuOpen={mobileMenuOpenHandler}
      />
    </div>
  )
}

export default Navbar
