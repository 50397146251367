import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
// import { StaticQuery, graphql } from "gatsby"

function SEO({ description, image, lang, meta, keywords, title, url }) {
  const metaTitle = title || "Alma — Simplifying Access to Therapy"
  const metaDescription =
    description ||
    "Alma makes it easy to find high-quality, affordable mental health care."
  const metaImage = image || "https://helloalma.com/og-hero.jpg"
  const metaUrl = url || "https://helloalma.com"
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:logo`,
          content:
            "https://secure.helloalma.com/static/website/images/logos/logo-primary%402x.png",
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: "Alma",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )

  // Removes graphQL query to siteMetadata due to caching issue in production - for now, the fallback values are hardcoded above to what exists in Gatsby congif site metadata.
  // return (
  //   <StaticQuery
  //     query={detailsQuery}
  //     render={data => {
  //       const metaTitle = title || data.site.siteMetadata.title
  //       const metaDescription =
  //         description || data.site.siteMetadata.description
  //       const metaImage = image || data.site.siteMetadata.image
  //       const metaUrl = url || data.site.siteMetadata.url
  //       return (
  //         <Helmet
  //           htmlAttributes={{
  //             lang,
  //           }}
  //           title={metaTitle}
  //           meta={[
  //             {
  //               name: `description`,
  //               content: metaDescription,
  //             },
  //             {
  //               property: `og:title`,
  //               content: metaTitle,
  //             },
  //             {
  //               property: `og:description`,
  //               content: metaDescription,
  //             },
  //             {
  //               property: `og:type`,
  //               content: `website`,
  //             },
  //             {
  //               property: `og:image`,
  //               content: metaImage,
  //             },
  //             {
  //               property: `og:url`,
  //               content: metaUrl,
  //             },
  //             {
  //               name: `twitter:card`,
  //               content: `summary_large_image`,
  //             },
  //             {
  //               name: `twitter:creator`,
  //               content: data.site.siteMetadata.author,
  //             },
  //             {
  //               name: `twitter:title`,
  //               content: title,
  //             },
  //             {
  //               name: `twitter:description`,
  //               content: metaDescription,
  //             },
  //           ]
  //             .concat(
  //               keywords.length > 0
  //                 ? {
  //                     name: `keywords`,
  //                     content: keywords.join(`, `),
  //                   }
  //                 : []
  //             )
  //             .concat(meta)}
  //         />
  //       )
  //     }}
  //   />
  // )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

// const detailsQuery = graphql`
//   query DefaultSEOQuery {
//     site {
//       siteMetadata {
//         title
//         description
//         image
//       }
//     }
//   }
// `
