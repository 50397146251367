import React, { useState, createContext } from "react"

const initialContext = {
  mobileMenuOpen: false,
  heroImageLoaded: false,
}

export const Context = createContext()
export const ContextDispatch = createContext()

const GlobalContextProvider = ({ children }) => {
  const [context, setContext] = useState(initialContext)

  return (
    <Context.Provider value={context}>
      <ContextDispatch.Provider value={setContext}>
        {children}
      </ContextDispatch.Provider>
    </Context.Provider>
  )
}

export default GlobalContextProvider
