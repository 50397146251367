import React, { useState, useEffect, useContext, useRef } from "react"
import { Link } from "gatsby"
import MenuArrow from "../svg/menu-arrow.inline.svg"
import LogoGreen from "../svg/logo-green.inline.svg"
import LogoWhite from "../svg/logo-white.inline.svg"
import HamburgerMenu from "react-hamburger-menu"
import ProfileIconGreen from "../svg/profile-icon.inline.svg"
import ProfileIconWhite from "../svg/profile-icon-white.inline.svg"
import { Context } from "../context/Context"
import { utmForwarding } from "../js/utm-forwarding"

function NavContent({
  knockoutNav,
  knockoutNavDark,
  navClosedDefault,
  setMobileMenuOpen,
  mobileMenuOpen,
}) {
  const [arrowState] = useState("closed")
  const [scrolling, setScrolling] = useState(false)
  const [navHidden, setNavHidden] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const context = useContext(Context)
  const getStartedDropdown = useRef()
  const forProvidersDropdown = useRef()

  useEffect(() => {
    if (!pageLoaded) {
      setTimeout(() => {
        setPageLoaded(true)
      }, 500)
      if (window.pageYOffset > 0) {
        setNavHidden(false)
        setScrolling(true)
      }
    }

    let initialScrollPos = 0
    const setScrollHandler = () => {
      if (window.pageYOffset === 0) {
        forProvidersDropdown.current.classList.contains("open") &&
          forProvidersDropdown.current.classList.remove("open")
        getStartedDropdown.current.classList.contains("open") &&
          getStartedDropdown.current.classList.remove("open")
        setScrolling(false)
        setNavHidden(false)
      } else {
        window.pageYOffset < 300 && setScrolling(true)
        if (window.pageYOffset < initialScrollPos) {
          setScrolling(true)
          setNavHidden(false)
          initialScrollPos = window.pageYOffset
        } else if (window.pageYOffset > initialScrollPos + window.innerHeight) {
          forProvidersDropdown.current.classList.contains("open") &&
            forProvidersDropdown.current.classList.remove("open")
          getStartedDropdown.current.classList.contains("open") &&
            getStartedDropdown.current.classList.remove("open")
          initialScrollPos = window.pageYOffset
          pageLoaded && setNavHidden(true)
        }
      }
    }
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", setScrollHandler)
    }
    return () => {
      window.removeEventListener("scroll", setScrollHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.mobileMenuOpen, pageLoaded])
  const onOpenDropdown = e => {
    if (e.currentTarget.classList.contains("open")) {
      e.currentTarget.classList.remove("open")
      e.currentTarget.classList.add("closed")
    } else {
      forProvidersDropdown.current.classList.contains("open") &&
        forProvidersDropdown.current.classList.remove("open")
      getStartedDropdown.current.classList.contains("open") &&
        getStartedDropdown.current.classList.remove("open")
      e.currentTarget.classList.remove("closed")
      e.currentTarget.classList.add("open")
      setScrolling(true)
    }
  }
  const handleMenuClick = async e => {
    if (
      e.currentTarget.href &&
      document.location.href.includes(e.currentTarget.href.split("#")[0]) &&
      typeof window !== "undefined"
    ) {
      setTimeout(() => {
        utmForwarding({
          expireDays: 30,
        })
      }, 50)
    }
    setMobileMenuOpen(!mobileMenuOpen)
  }
  return (
    <div
      className={`nav-container rel f jcb aic fix ${
        context && context.mobileMenuOpen ? "  mobile-open" : ""
      } x z7 ${scrolling ? "scrolled" : "scroll-top"}${
        knockoutNav && !mobileMenuOpen ? " knockoutNav " : ""
      } ${
        knockoutNavDark && !mobileMenuOpen ? " knockoutNavDark " : ""
      }  scrollNav ${navHidden ? "hide " : ""}`}
    >
      <nav className={`f jcb x z2 aic`}>
        <HamburgerMenu
          className={`mobile-menu ${mobileMenuOpen ? "" : ""}`}
          isOpen={mobileMenuOpen || false}
          menuClicked={handleMenuClick}
          width={18}
          height={15}
          strokeWidth={2}
          rotate={0}
          color={
            knockoutNav && !mobileMenuOpen && !scrolling ? "white" : "#02291c"
          }
          borderRadius={0}
          animationDuration={0.5}
        />
        <Link
          to="/"
          title="Home Link"
          className={`logo f aic rel ${knockoutNav ? "white" : "green"}`}
        >
          {knockoutNav && !scrolling && !mobileMenuOpen ? (
            <LogoWhite className={``} />
          ) : (
            <LogoGreen />
          )}
        </Link>
        <ul
          className={`menu-items f aic rel ${
            mobileMenuOpen ? "mobile-open" : ""
          }`}
        >
          <li className="main-item mx1">
            <div
              id="get-started"
              className={`f aic pointer ${arrowState}`}
              onClick={e => onOpenDropdown(e)}
              onKeyDown={e => onOpenDropdown(e)}
              tabIndex={-1}
              role="menu"
              ref={getStartedDropdown}
            >
              <div className="inner-container f jcs aic x">
                <p className="item-title mr025">Get Started</p>
                <MenuArrow className="menu-arrow" />
              </div>

              <ul
                className={`nav-dropdown ${
                  arrowState === "open" ? "open" : ""
                }`}
              >
                <li className="sub-item">
                  <a
                    role="menuitem"
                    href="https://secure.helloalma.com/providers-landing/"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>Find A Provider</p>
                  </a>
                </li>
                <li className="sub-item">
                  <Link
                    role="menuitem"
                    to="/get-matched"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>Get Matched</p>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="main-item mx1">
            <Link
              to="/how-it-works"
              className="item-title"
              onClick={handleMenuClick}
              onKeyDown={handleMenuClick}
            >
              How It Works
            </Link>
          </li>
          <li className="main-item mx1">
            <div
              id="for-providers"
              className={`f aic pointer ${arrowState}`}
              onClick={e => onOpenDropdown(e)}
              onKeyDown={e => onOpenDropdown(e)}
              role="menu"
              tabIndex={-1}
              ref={forProvidersDropdown}
            >
              <div className="inner-container f jcs aic x">
                <p className="mr025 item-title">For Providers</p>
                <MenuArrow className="menu-arrow" />
              </div>

              <ul
                className={`nav-dropdown ${
                  arrowState === "open" ? "open" : ""
                }`}
              >
                <li className="sub-item">
                  <Link
                    role="menuitem"
                    to="/for-providers"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>Benefits</p>
                  </Link>
                </li>
                {/* <li className="sub-item" >
                  <Link role="menuitem"
                    to="/for-providers#form"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>Insurance At Alma</p>
                  </Link>
                </li> */}
                <li className="sub-item">
                  <Link
                    role="menuitem"
                    to="/for-providers#form"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>Join Alma</p>
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="main-item mx1">
            <div
              id="for-providers"
              className={`f aic pointer ${arrowState}`}
              onClick={e => onOpenDropdown(e)}
              onKeyDown={e => onOpenDropdown(e)}
              role="menu"
              tabIndex={-1}
              ref={forProvidersDropdown}
            >
              <div className="inner-container f jcs aic x">
                <p className="mr025 item-title">About Alma</p>
                <MenuArrow className="menu-arrow" />
              </div>

              <ul
                className={`nav-dropdown ${
                  arrowState === "open" ? "open" : ""
                }`}
              >
                <li className="sub-item">
                  <Link
                    role="menuitem"
                    to="/our-mission"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>Our Mission</p>
                  </Link>
                </li>
                <li className="sub-item">
                  <Link
                    role="menuitem"
                    to="/locations"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>Where We Are</p>
                  </Link>
                </li>
                <li className="sub-item">
                  <Link
                    role="menuitem"
                    to="/faq"
                    onClick={handleMenuClick}
                    onKeyDown={handleMenuClick}
                  >
                    <p>FAQs</p>
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          {/* <li className="main-item mx1">
            <Link
              to="/our-mission"
              className="item-title"
              onClick={handleMenuClick}
              onKeyDown={handleMenuClick}
            >
              Our Mission
            </Link>
          </li> */}
          <li className="main-item ml15 mr05 login">
            <a
              className="btn light px1"
              href="https://helloalma.com/login/"
              onClick={handleMenuClick}
              onKeyDown={handleMenuClick}
            >
              <p>Provider login</p>
            </a>
          </li>
          <li className="main-item mx05 find-a-provider-btn">
            <a
              className="btn dark "
              href="https://secure.helloalma.com/providers-landing/"
              onClick={handleMenuClick}
              onKeyDown={handleMenuClick}
            >
              <p>Find a provider</p>
            </a>
          </li>
        </ul>
        <a
          href="https://secure.helloalma.com/login/"
          className="profile-icon f mobile-menu"
          title="login"
        >
          {context && context.mobileMenuOpen ? (
            <ProfileIconGreen />
          ) : scrolling || !knockoutNav ? (
            <ProfileIconGreen />
          ) : (
            <ProfileIconWhite />
          )}
        </a>
      </nav>
    </div>
  )
}

export default NavContent
