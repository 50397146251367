import React, { useContext, useEffect } from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"
import "../styles/main.scss"
import "../styles/global/componentsStyling/index.scss"
import { Context } from "../context/Context"
import EmailFloater from "../components/emailFloater"
import Helmet from "react-helmet"
import { utmForwarding } from "../js/utm-forwarding"

function Layout({
  children,
  knockoutNav,
  knockoutNavDark,
  scrollNav,
  navClosedDefault,
  removeFooterCallout,
  pressInFooter,
  pressSubhead,
  newLayout,
  footerBackgroundColor,
  emailFloater,
  id,
  footerEyebrow,
  footerHeader,
  footerSubhead,
  footerCtas,
  footerLinks,
}) {
  const context = useContext(Context)

  const scrollListener = (scrollModules, scrollModulesDelayed) => {
    scrollModules.forEach(module => {
      if (window.scrollY >= module.offsetTop - 700) {
        module.classList.add("faded")
        const svgs = module.querySelectorAll(".svg-animate")
        svgs.length &&
          [].forEach.call(svgs, svg => {
            svg.classList.add("faded")
          })
      }
    })
    scrollModulesDelayed.forEach(module => {
      if (window.scrollY >= module.offsetTop + 500) {
        module.classList.add("faded")
        module.querySelector(".svg-animate") &&
          module.querySelector(".svg-animate").classList.add("faded")
      }
    })
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      utmForwarding({
        expireDays: 30,
      })
      const loadModules = document.querySelectorAll(".fade-load")
      const scrollModules = document.querySelectorAll(".fade-in")
      const scrollModulesDelayed = document.querySelectorAll(".fade-in-delay")
      loadModules.forEach(module => {
        module.classList.add("faded")
      })
      scrollModules.forEach((module, idx) => {
        // checks if any hidden modules are visible in top 3/4 of fold and fades them in
        module.offsetTop < window.scrollY + window.innerHeight * (3 / 4) &&
          setTimeout(() => {
            module.classList.add("faded")
            module.querySelector(".svg-animate") &&
              module.querySelector(".svg-animate").classList.add("faded")
          }, 1000)
      })

      window.addEventListener(
        "scroll",
        scrollListener.bind(this, scrollModules, scrollModulesDelayed)
      )
    }
    return () => {
      window.removeEventListener("scroll", scrollListener)
    }
  }, [])
  return (
    <div className={`outer-container gradient rel `} id={id}>
      <Helmet>
        <script type="text/javascript">
          {`(function() {
            var didInit = false;
            function initMunchkin() {
              if(didInit === false) {
                didInit = true;
                Munchkin.init('894-SZP-051');
              }
            }
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//munchkin.marketo.net/munchkin.js';
            s.onreadystatechange = function() {
              if (this.readyState == 'complete' || this.readyState == 'loaded') {
                initMunchkin();
              }
            };
            s.onload = initMunchkin;
            document.getElementsByTagName('head')[0].appendChild(s);
          })();`}
        </script>
      </Helmet>
      <Navbar
        knockoutNav={knockoutNav}
        knockoutNavDark={knockoutNavDark}
        scrollNav={scrollNav}
        navClosedDefault={navClosedDefault}
      />
      <div
        className={`layover abs fill z6${
          context && context.mobileMenuOpen ? " shade " : ""
        }`}
      ></div>
      <main>
        {emailFloater && <EmailFloater />}
        {children}
      </main>
      <Footer
        pressInFooter={pressInFooter}
        pressSubhead={pressSubhead}
        removeFooterCallout={removeFooterCallout}
        footerBackgroundColor={footerBackgroundColor}
        footerEyebrow={footerEyebrow}
        footerHeader={footerHeader}
        footerSubhead={footerSubhead}
        footerCtas={footerCtas}
        footerLinks={footerLinks}
      />
    </div>
  )
}

export default Layout
