import React, { useEffect, useRef } from "react"
import "./PressModule.scss"
const PressModule = ({
  logos,
  pressContext,
  header,
  backgroundColor,
  textColor,
  stacked,
}) => {
  const pressLogosEl = useRef()
  const insuranceLogosEl = useRef()
  const investorLogosEl = useRef()
  useEffect(() => {
    if (pressContext === "insurance") {
      insuranceLogosEl.current
        .querySelector(".press-container")
        .classList.add("faded")
    }
    if (typeof window !== "undefined") {
      const scrollHandler = () => {
        if (
          pressLogosEl.current &&
          window.scrollY >= pressLogosEl.current.offsetTop - 700
        ) {
          pressLogosEl.current
            .querySelector(".press-container")
            .classList.add("faded")
        }
        if (
          investorLogosEl.current &&
          window.scrollY >= investorLogosEl.current.offsetTop - 700
        ) {
          investorLogosEl.current
            .querySelector(".press-container")
            .classList.add("faded")
        }
      }
      window.addEventListener("scroll", scrollHandler)
      return () => {
        window.removeEventListener("scroll", scrollHandler)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  let refContext
  if (pressContext === "footer") {
    refContext = pressLogosEl
  } else if (pressContext === "investors") {
    refContext = investorLogosEl
  } else {
    refContext = insuranceLogosEl
  }
  return (
    <div
      className={`press-module outer-x page-module ${
        backgroundColor ? backgroundColor : ""
      } ${pressContext}-callout`}
      ref={refContext}
    >
      <div
        className={`press-container f x jcb container--xxl ma fade-in p1 aic ${
          stacked ? "stacked" : ""
        }`}
      >
        {header && (
          <div className="press-header press-logo">
            <p className={`${textColor || "cgr"}`}>{header}</p>
          </div>
        )}
        {logos &&
          logos.map((logo, idx) => {
            let Logo = logo[0]
            return (
              <div className="press-logo" key={idx}>
                {logo[1] ? (
                  <a
                    href={logo[1]}
                    target="_blank"
                    rel="noreferrer"
                    className="f"
                  >
                    {typeof logo[0] === "string" ? (
                      <img className="x" src={logo[0]} alt="press logo" />
                    ) : (
                      <Logo className="" />
                    )}
                  </a>
                ) : (
                  <>
                    {typeof logo[0] === "string" ? (
                      <img className="x" src={logo[0]} alt="press logo" />
                    ) : (
                      <Logo className="" />
                    )}
                  </>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}
export default PressModule
